<template>
  <v-container class="py-7">
    <div class="heading mb-3">{{ text.text_1 }}</div>

    <div class="mb-3">
      <v-chip color="error" class="mr-2">{{ level }}</v-chip>
      <b>{{ text.text_2 }}</b>
    </div>

    <div class="mb-6">{{ text.text_3 }}</div>

    <v-card
      class="gift mb-6 mx-auto"
      v-for="(gift, i) in gifts"
      :key="i"
      max-width="280px"
      :class="{
        active: gift.item_id == selectedID,
        disabled: selectedID && gift.item_id != selectedID,
      }"
    >
      <v-img :src="gift.photo"></v-img>
      <v-card-title class="pb-1">{{ gift.name }}</v-card-title>
      <v-card-text>{{ gift.description }}</v-card-text>
      <div
        class="bar"
        :class="{ 'not-available': !gift.available }"
        :style="`width: ${gift.progress_percent}%;`"
      ></div>
      <v-card-text class="text-center pa-0">
        <template v-if="gift.available">
          <v-btn
            v-if="gift.item_id == selectedID"
            block
            tile
            depressed
            large
            color="error"
            @click="removeGift(gift.item_id)"
            >Отменить</v-btn
          >
          <template v-else>
            <v-btn
              v-if="!selectedID"
              text
              block
              tile
              large
              @click="selectGift(gift.item_id)"
              >Выбрать</v-btn
            >
            <div v-else class="pa-3 secondary--text">Выбран другой подарок</div>
          </template>
        </template>
        <div class="pa-3 secondary--text" v-else>
          {{ giftTruncatedText(gift) }}
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Gift',

  methods: {
    async selectGift(id) {
      const { commit, dispatch } = this.$store
      commit('overlay/update', { active: true })
      await dispatch('gift/addGift', id)
      commit('overlay/update', { active: false })
    },

    async removeGift(id) {
      const { commit, dispatch } = this.$store
      commit('overlay/update', { active: true })
      await dispatch('gift/removeGift', id)
      commit('overlay/update', { active: false })
    },

    giftTruncatedText(gift) {
      if (!gift) return 'Недоступно'

      const $text = gift?.available_text

      const $sum = +this.itemsPrice

      let $remainSum = $sum

      const $availableSum = /(\d+)$/gim

      if ($availableSum.test($text)) {
        $remainSum = +(
          $text.match($availableSum) && $text.match($availableSum)[0]
        )
      }

      $remainSum = $remainSum - $sum

      return `До подарка ${$remainSum} ${this.$store.getters['app/currency']}`
    },
  },

  computed: {
    ...mapGetters('checkout', ['itemsPrice']),
    level() {
      return this.data?.context?.current_level || 0
    },

    text() {
      return this.data?.text
    },

    gifts() {
      return this.data?.items
    },

    cartGift() {
      return this.$store.state.checkout?.cart?.gift_cart?.gift_featured
    },

    selectedID() {
      return this.cartGift?.item_id
    },

    ...mapState('gift', ['data']),
  },
}
</script>

<style lang="scss" scoped>
.gift {
  border-radius: 2px !important;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1) !important;

  &.active {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25) !important;
  }

  &.disabled {
    opacity: 0.75;
  }
}

.bar {
  width: 100%;
  height: 2px;
  background: #83cd26;

  &.not-available {
    background: linear-gradient(
      90deg,
      #ed372c 0%,
      #f66d0a 68.75%,
      #ffdd2d 126.04%,
      #83cd26 200%
    );
  }
}
</style>
